<template>
  <div>
    <b-card>
      <div class="map-container-tracker" v-if="status_mapa">
          <l-map :center="center" :zoom="zoom" v-if="response != null" ref="mymap" :options="{zoomControl: false}">
              <l-control-layers :collapsed="true" :position="layersPosition" :sort-layers="true"/>
              <l-tile-layer :key="tileProvider.key" :name="tileProvider.name" :url="tileProvider.url" :visible="tileProvider.visible" layer-type="base" v-for="tileProvider in tileProviders"/>
              <l-control-zoom position="topleft" :options="{ zoomInTitle: $t('Labels.ZoomIn'), zoomOutTitle: $t('Labels.ZoomOut') }"/>
              <l-control position="topleft" v-if="showButtons === true">
                <b-button :to="`/groups/${uuid}`" class="btn-icon btn-map rounded-circle" variant="primary"  :title="$t('Labels.Groups')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }">
                  <feather-icon icon="UsersIcon" :width="18" :height="18"/>
                </b-button>
                <b-button :to="`/contacts/${uuid}`" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.Contacts')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }">
                  <feather-icon icon="UserIcon" :width="18" :height="18"/>
                </b-button>
                <b-button  @click="shareLocation()" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.ShareLocation')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }">
                  <feather-icon icon="ExternalLinkIcon" :width="18" :height="18"/>
                </b-button>
                <b-button @click="showTransfer = !showTransfer" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.Transfer')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }" v-if="$store.state.session.AppActiveUser().userRole !== 'TP'">
                  <feather-icon icon="RefreshCwIcon" :width="18" :height="18"/>
                </b-button>
                <b-button @click="showTrail = !showTrail" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.ShowTrail')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }">
                  <iconBase :width="18" :height="18" iconColor="#FFFF"> <iconDistance/></iconBase>
                </b-button>
                <b-button @click="showHistory = !showHistory" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.ShowHistory')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }">
                  <iconBase :width="18" :height="18" iconColor="#FFFF"> <iconHistory/></iconBase>
                </b-button>
                <b-button :to="`/tracker/statistics/${uuid}`" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.State')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }" v-if="$store.state.session.AppActiveUser().userRole === 'TO'">
                  <feather-icon icon="ActivityIcon" :width="18" :height="18"/>
                </b-button>
                <b-button @click="showConfig = !showConfig" class="btn-icon btn-map rounded-circle" variant="secondary" :title="$t('Labels.Config')" v-b-tooltip.hover="{ variant: 'secondary', placement: 'right' , placement: 'right' }" v-if="$store.state.session.AppActiveUser().userRole !== 'TP'">
                  <feather-icon icon="SettingsIcon" :width="18" :height="18"/>
                </b-button>
              </l-control>
              <l-control position="bottomleft">
                <b-card v-if="showTransfer">
                  <b-row align-h="end">
                    <b-col>
                      <h4 class="mt-1">{{$t('Labels.Transfer')}}</h4>
                    </b-col>
                    <b-col>
                      <b-button variant="flat-primary" class="btn-icon float-right btn-map rounded-circle" @click="showTransfer = false">
                        <feather-icon icon="XIcon" :width="18" :height="18"/>
                      </b-button>
                    </b-col>
                  </b-row>
                  <div>
                    <b-form class="" @submit.prevent>
                      <validation-observer ref="userValidation">
                      <b-row align-v="center">
                        <b-col cols="12" md="10">
                          <b-form-group :label="$t('Labels.ToUser')" label-for="user">
                            <validation-provider #default="{ errors }" name="deuservice" rules="required">
                              <b-form-select name="user" :state="errors.length > 0 ? false:null" :options="users" v-model="userUuid" value-field="uuid" text-field="email" :clearable="false">
                                <template #first>
                                  <b-form-select-option :value="null" disabled>{{$tc('Labels.SelectUser')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2" class="pl-0">
                          <b-button variant="primary" class="btn-icon btn-transfer" @click="userValidation">
                            <feather-icon icon="SendIcon" :width="18" :height="18" />
                          </b-button>
                        </b-col>
                      </b-row>
                      </validation-observer>
                    </b-form>
                    <b-form class="" @submit.prevent>
                      <validation-observer ref="emailValidation">
                      <b-row align-v="center">
                        <b-col cols="12" md="10">
                          <b-form-group :label="$t('Labels.SendPINExternal')" label-for="email">
                          <validation-provider #default="{ errors }" name="email" rules="email">
                            <b-form-input id="email" v-model="emailTransfer" name="email" :placeholder="$t('Email')"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2" class="pl-0">
                          <b-button class="btn-icon btn-transfer" variant="primary" @click="validationEmail">
                            <feather-icon icon="SendIcon" :width="18" :height="18" />
                          </b-button>
                        </b-col>
                      </b-row>
                      </validation-observer>
                    </b-form>
                  </div>
                </b-card>
                <b-card v-if="showHistory">
                  <b-row>
                    <b-col cols="12" md="8">
                      <b-form>
                        <b-form-group
                          :label="$t('Labels.Filter')"
                          label-for="filter"
                        >
                            <b-form-select
                              name="filter"
                              :options="optionsHistoryFilter"
                              v-model="historyFilter"
                              :clearable="false"
                            >
                            </b-form-select>
                        </b-form-group>
                      </b-form>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-button
                        class="mt-2"
                        variant="primary"
                        @click="getHistory()"                        >
                        {{$t('Labels.Filter')}}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </l-control>
              <l-control position="topright">
                <b-button v-if="!showCardInfo && !showConfig" @click="showCardInfo = !showCardInfo" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.TrackerInfo')" v-b-tooltip.hover="{ variant: 'secondary' }" >
                  <feather-icon icon="InfoIcon" :width="18" :height="18"/>
                </b-button>
                <b-card v-if="!showConfig && showCardInfo">
                  <b-row align-h="end">
                    <b-col>
                      <b-button
                        variant="flat-primary"
                        class="btn-icon float-right btn-map rounded-circle"
                        @click="showCardInfo = false"
                      >
                        <feather-icon icon="XIcon" :width="18" :height="18" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <valueItem :value="response.name" :description="$t('Labels.Alias')" img="pointtracker"/>
                  <valueItem v-if="response.user" :value="response.user.email || $t('Labels.NotAssigned')" :description="$tc('Labels.User',1)" img="email"/>
                  <valueItem :value="response.serie" :description="$t('Labels.Serie')" img="alias"/>
                  <valueItem :value="this.latitud" :description="$t('Labels.Latitude')" img="georeference"/>
                  <valueItem :value="this.longitud" :description="$t('Labels.Longitude')" img="georeference"/>
                  <valueItem :value="batteryCalculate(response.battery)" :description="$t('Labels.Battery')" img="battery" unit="%"/>
                  <hr>
                  <valueItem :value="response.updated_at.split(' ')[0]" :description="response.updated_at.split(' ')[1]" img="fecha"></valueItem>
                  <alertTracker :status="response.tipo_alerta" />
                </b-card>
                <b-card v-if="showConfig">
                  <h4>
                      {{$t("Labels.TrackerInfo")}}
                  </h4>
                  <b-form
                    class=""
                    @submit.prevent
                  >
                    <validation-observer ref="configValidation">
                      <b-form-group
                        :label="$t('Labels.Alias')"
                        label-for="alias"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Alias"
                          rules="required"
                        >
                          <b-form-input id="alias" v-model="response.name" :state="errors.length > 0 ? false:null" name="alias" :placeholder="$t('Labels.Alias')" v-on:keyup.13="validationForm()" maxlength="254" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <h5 class="text-center mt-2">{{$t("Labels.Alerts")}}</h5>
                      <hr>
                      <b-row v-align="center">
                        <b-col>
                          <b-card-text class="mb-0">
                            {{$t("Labels.Notifications")}}
                          </b-card-text>
                          <b-form-checkbox
                            checked="true"
                            class="custom-control-primary"
                            name="check-button"
                            switch
                            v-model="response.notifications"
                          >
                            <span class="switch-icon-left">
                              <feather-icon icon="BellIcon" />
                            </span>
                            <span class="switch-icon-right">
                              <feather-icon icon="BellOffIcon" />
                            </span>
                          </b-form-checkbox>
                        </b-col>
                        <b-col>
                          <b-card-text class="mb-0">
                            <span v-if="response.alertInOut">{{$t("Labels.Enter")}}</span>
                            <span v-else>{{$t("Labels.Exit")}}</span>
                          </b-card-text>
                          <b-form-checkbox
                            checked="true"
                            class="custom-control-primary"
                            name="check-button"
                            switch
                            v-model="response.alertInOut"
                          >
                            <span class="switch-icon-left">
                              <iconBase iconName="enter" :width="18" :height="18" iconColor="#626262"><iconLocationEnter/></iconBase>
                            </span>
                            <span class="switch-icon-right">
                              <iconBase iconName="exit" :width="18" :height="18" iconColor="#626262"><iconLocationExit/></iconBase>
                            </span>
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <b-col>
                          <b-card-text class="mb-0">
                            {{$t("Labels.RadiusAlert")}}
                          </b-card-text>
                          <vue-slider
                            v-model="radiusAlert"
                            :min="minRadius"
                            :max="maxRadius"
                            direction="ltr"
                            :interval="1"
                          />
                          <div>{{this.radiusAlert}} mts</div>
                        </b-col>
                      </b-row>
                      <h5 class="text-center mt-2">{{$t("Labels.ReportTime")}}</h5>
                      <hr>
                      <b-row v-align="center">
                        <b-col>
                          <b-form-spinbutton
                            :min="1"
                            v-model="response.time_notifications"
                            :max="1000"
                            class="text-xs"
                            size="sm"
                          />
                        </b-col>
                        <b-col>
                          <span>{{$t("Labels.Seconds")}}</span>
                        </b-col>
                      </b-row>
                      <b-button
                        type="submit"
                        variant="primary"
                        block
                        @click="validationForm"
                        class="mt-1"
                      >
                        {{$t("Labels.Save")}}
                      </b-button>
                    </validation-observer>
                  </b-form>
                </b-card>
              </l-control>
              <l-marker :draggable="false" :lat-lng="marker" v-if="marker != null">
                <l-icon :icon-size="[40,40]">
                  <avatarMarker
                    :label="response.name.substr(0,2)"
                    :status="response.tipo_alerta"
                  />
                </l-icon>
              </l-marker>
              <l-layer-group :visible="showConfig">
                <l-circle
                  :lat-lng="centerAlert"
                  :radius="this.radiusAlert"
                  color="#EA5455"
                  attribution="Alert"
                />
                <l-marker :draggable="draggable" :lat-lng.sync="centerAlert" ref="position_marker">
                    <l-icon :icon-anchor="[12, 27]" >
                      <img :src="require(`@/assets/images/markers/danger.svg`)" width="25px;" alt="centro alerta"/>
                  </l-icon>
                </l-marker>
              </l-layer-group>
              <l-layer-group :visible="showTrail">
                <l-circle-marker v-for="(point, index) in trailPointers" :key="index"
                  :lat-lng="point.position"
                  :radius="5"
                  :color="point.status"
                />
                <v-polyline-decorator :paths="[polylinePointers]" :patterns="patternsTrail" />
                <l-polyline :lat-lngs="polylinePointers" color="#00c3c3" />
              </l-layer-group>
              <l-layer-group :visible="showHistory">
                <l-circle-marker v-for="(point, index) in historyPointers" :key="index"
                  :lat-lng="point.position"
                  :radius="5"
                  :color="point.status"
                />
                <v-polyline-decorator :paths="[historyPolyline]" :patterns="patternsHistory" />
                <l-polyline :lat-lngs="historyPolyline" color="#00c3c3" />
              </l-layer-group>
          </l-map>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LControl,
  LControlLayers,
  LControlZoom,
  LCircle,
  LCircleMarker,
  LLayerGroup,
  LPolyline
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import valueItem from '@core/spore-components/valueItem/valueItem.vue'
import request from '@/libs/request/index'
import { empty, batteryCalculate } from '@/libs/tools/helpers'
import alertTracker from '@core/spore-components/alertTracker/alertTracker.vue'
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BFormSpinbutton,
  BFormCheckbox
} from 'bootstrap-vue'
import L from 'leaflet'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
import avatarMarker from '@core/spore-components/avatarMarker/avatarMarker.vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import iconBase from '@core/spore-components/icons/iconBase'
import iconLocationEnter from '@core/spore-components/icons/iconLocationEnter'
import iconLocationExit from '@core/spore-components/icons/iconLocationExit'
import iconDistance from '@core/spore-components/icons/iconDistance'
import iconHistory from '@core/spore-components/icons/iconHistory'
import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator'
import { app } from '@/main'

const tileProviders = [
  {
    key: 'gray',
    name: app.$t('Labels.Gray'),
    visible: true,
    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
  },
  {
    key: 'street',
    name: app.$t('Labels.Street'),
    visible: false,
    url: 'https://{s}.tile.openstreetmap.fr/osmfr//{z}/{x}/{y}.png'
  },
  {
    key: 'satellite',
    name: app.$t('Labels.Satellite'),
    visible: false,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
  }
]

const DATA = {
  code: 200,
  data: {},
  msg: 'Ok'
}

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    BCard,
    BCardText,
    LControl,
    LControlLayers,
    LControlZoom,
    BButton,
    valueItem,
    alertTracker,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormSelectOption,
    avatarMarker,
    BFormSpinbutton,
    BFormCheckbox,
    VueSlider,
    iconBase,
    iconLocationEnter,
    iconLocationExit,
    LCircle,
    iconDistance,
    LCircleMarker,
    LLayerGroup,
    LPolyline,
    'v-polyline-decorator': Vue2LeafletPolylineDecorator,
    iconHistory
  },

  data () {
    return {
      zoom: 17,
      center: L.latLng(47.41322, -1.219482),
      latGDL: '20.6749218',
      longGDL: '-103.3866663',
      latitud: 0,
      longitud: 0,
      url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      tileProviders,
      layersPosition: 'topleft',
      attribution: '',
      marker: null,
      draggable: true,
      staticAnchor: [12, 27],
      response: null,
      status_mapa: false,
      uuid: this.$route.params.uuid,
      limits: {},
      DATA,
      batteryCalculate,
      showTransfer: false,
      emailTransfer: '',
      required,
      users: [],
      userUuid: null,
      showButtons: false,
      showConfig: false,
      minRadius: parseInt(process.env.VUE_APP_MIN_RADIUS) || 50,
      maxRadius: parseInt(process.env.VUE_APP_MAX_RADIUS) || 1000,
      spin: 10,
      showTrail: false,
      radiusAlert: 0,
      centerAlert: null,
      trailPointers: [],
      polylinePointers: [],
      showCardInfo: true,
      showHistory: false,
      optionsHistoryFilter: [
        { text: this.$t('Labels.LastHour'), value: 'H' },
        { text: this.$t('Labels.Last8Hours'), value: '8H' },
        { text: this.$t('Labels.Last24Hours'), value: 'D' },
        { text: this.$t('Labels.LastWeek'), value: 'W' }
      ],
      historyFilter: 'H',
      historyPointers: [],
      historyPolyline: [],
      patternsTrail: [{
        offset: 25,
        repeat: 50,
        symbol: L.Symbol.arrowHead({
          pixelSize: 15,
          pathOptions: {
            fillOpacity: 1,
            weight: 5
          }
        })
      }],
      patternsHistory: [{
        offset: '100%',
        repeat: 0,
        symbol: L.Symbol.arrowHead({
          pixelSize: 15,
          polygon: false,
          pathOptions: {
            stroke: true,
            color: '#00c3c3'
          }
        })
      }]
    }
  },

  methods: {
    async getResponse () {
      const params = {
        url: `tracker/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        this.response = data.data.data
        if (!empty(this.response.enabled_notifications)) {
          this.response.notifications = this.response.enabled_notifications === '1'
        }
        if (!empty(this.response.alert_in_radius)) {
          this.response.alertInOut = this.response.alert_in_radius === 'I'
        }
        this.response.time_notifications = parseInt(this.response.time_notifications)
        if (!empty(this.response.latlong_alert_string)) {
          this.centerAlert = L.latLng(this.response.latlong_alert_string.split(',')[0], this.response.latlong_alert_string.split(',')[1])
        }
        if (!empty(this.response.lat) && !empty(this.response.long) && this.response.lat !== '0' && this.response.long !== '0') {
          this.latitud = this.response.lat
          this.longitud = this.response.long
          this.marker = L.latLng(this.response.lat, this.response.long)
          this.center = L.latLng(this.response.lat, this.response.long)
          this.status_mapa = true
          const app = this
          setTimeout(function () {
            const maps = app.$refs.mymap.mapObject
            maps.addControl(new L.Control.Fullscreen({
              position: 'topleft',
              title: {
                false: app.$t('Labels.Fullscreen'),
                true: app.$t('Labels.ExitFullscreen')
              }
            }))
            app.showButtons = true
          }, 1000)
        } else {
          this.latitud = this.latGDL
          this.longitud = this.longGDL
          this.marker = L.latLng(this.latGDL, this.longGDL)
          this.center = L.latLng(this.latGDL, this.longGDL)
          this.status_mapa = true
          const app = this
          setTimeout(function () {
            const maps = app.$refs.mymap.mapObject
            maps.addControl(new L.Control.Fullscreen({
              position: 'topleft',
              title: {
                false: app.$t('Labels.Fullscreen'),
                true: app.$t('Labels.ExitFullscreen')
              }
            }))
            app.showButtons = true
          }, 1000)
        }
        this.radiusAlert = (!empty(this.response.radius_alert) ? parseInt(this.response.radius_alert) : 0)
        if (this.radiusAlert < this.minRadius) {
          this.radiusAlert = this.minRadius
        }
      })
    },
    async shareLocation () {
      const params = {
        url: `tracker_public/${this.uuid}`,
        method: 'POST',
        customMessages: true
      }
      await request(params).then(data => {
        this.$copyText(`${process.env.VUE_APP_BASE_URL || ''}share/${data.data.data.uuid}`).then(() => {
          this.$swal({
            title: this.$t('Messages.GeneratedURL'),
            text: this.$t('Messages.URLCopied'),
            timer: 5000,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          })
        }, () => {
          this.$swal({
            title: this.$t('Messages.URLError'),
            text: '',
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        })
      }).catch(() => {
        this.$swal({
          title: this.$t('Messages.URLError'),
          text: '',
          timer: 5000,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
      })
    },
    validationEmail () {
      this.$refs.emailValidation.validate().then(success => {
        if (success) {
          this.sendPIN()
        }
      })
    },
    async sendPIN () {
      const params = {
        url: 'tracker/device/assignUser',
        method: 'POST',
        customMessages: true,
        params: {
          tracker_uuid: this.uuid,
          email: this.emailTransfer
        }
      }
      await request(params).then(() => {
        this.emailTransfer = ''
        this.showTransfer = false
        this.$swal({
          title: this.$t('Messages.Success'),
          text: this.$t('Messages.PINSent'),
          timer: 5000,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        })
      }).catch(error => {
        if (error.response.data.code === 404) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.UserNotFound'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        } else {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.FailMessage'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        }
      })
    },
    async getUsers () {
      const params = {
        url: 'tracker_users/users',
        method: 'GET'
      }
      await request(params).then(response => {
        this.users = response.data.data
      })
    },
    userValidation () {
      this.$refs.userValidation.validate().then(success => {
        if (success) {
          this.assignUser()
        }
      })
    },
    async assignUser () {
      const params = {
        url: 'tracker/device/carrier',
        method: 'POST',
        customMessages: true,
        params: {
          tracker_uuid: this.uuid,
          user_uuid: this.userUuid
        }
      }
      await request(params).then(() => {
        this.showTransfer = false
        this.$swal({
          title: this.$t('Messages.Success'),
          text: this.$t('Messages.AssignSuccess'),
          timer: 5000,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        })
      }).catch(() => {
        this.$swal({
          title: this.$t('Messages.Fail'),
          text: this.$t('Messages.FailMessage'),
          timer: 5000,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
      })
    },
    async save () {
      const location = this.$refs.position_marker.mapObject.getLatLng()
      const params = {
        url: `tracker/${this.uuid}`,
        method: 'PUT',
        params: {
          latlong_alert_string: `${location.lat},${location.lng}`,
          name: this.response.name,
          alert_in_radius: this.response.alertInOut ? 'I' : 'O',
          radius_alert: this.radiusAlert,
          enabled_notifications: this.response.notifications ? '1' : '0',
          time_notifications: this.response.time_notifications
        }
      }
      await request(params).then(() => {
        this.showConfig = false
        const maps = this.$refs.mymap.mapObject
        maps.fitBounds([[this.marker.lat, this.marker.lng]])
      })
    },
    validationForm () {
      this.$refs.configValidation.validate().then(success => {
        if (success) {
          this.save()
        }
      })
    },
    async getHistory () {
      const params = {
        url: `tracker/${this.uuid}/log`,
        method: 'GET',
        params: {
          filter: this.historyFilter
        }
      }
      await request(params).then(data => {
        this.showCardInfo = false
        this.showTrail = false
        data = data.data.data.rows
        this.historyPointers = []
        this.historyPolyline = []
        this.patternsHistory = []
        data.forEach(payload => {
          if (!empty(payload.lat) && payload.lat !== '0' && !empty(payload.long) && payload.long !== '0') {
            this.historyPointers.push({
              position: [payload.lat, payload.long],
              status: payload.tipo_alerta === 'S' ? '#28c76f' : '#EA5455'
            })
            this.historyPolyline.push([payload.lat, payload.long])
          }
        })
        const maps = this.$refs.mymap.mapObject
        maps.fitBounds(this.historyPolyline)
      })
    }
  },
  watch: {
    showTrail (val) {
      if (val) {
        this.showCardInfo = false
        this.showHistory = false
        const maps = this.$refs.mymap.mapObject
        maps.fitBounds(this.polylinePointers)
      }
    },
    showConfig (val) {
      if (val) {
        this.showHistory = false
        const maps = this.$refs.mymap.mapObject
        const pointsCenter = []
        pointsCenter.push([this.marker.lat, this.marker.lng])
        if (!empty(this.centerAlert)) {
          pointsCenter.push([this.centerAlert.lat, this.centerAlert.lng])
        }
        maps.fitBounds(pointsCenter)
      }
    }
  },
  sockets: {
    trackers (payload) {
      if (payload.uuid === this.uuid) {
        if (!empty(payload.lat) && payload.lat !== '0' && !empty(payload.long) && payload.long !== '0') {
          this.marker = L.latLng(payload.lat, payload.long)
          this.center = L.latLng(payload.lat, payload.long)
          this.trailPointers.push({
            position: [payload.lat, payload.long],
            status: payload.tipo_alerta === 'S' ? '#28c76f' : '#EA5455'
          })
          this.polylinePointers.push([payload.lat, payload.long])
          this.latitud = payload.lat
          this.longitud = payload.long
          this.response.tipo_alerta = payload.tipo_alerta
          this.response.battery = payload.battery
          this.response.updated_at = payload.updated_at
          this.response.name = payload.name
        }
      }
    }
  },
  created () {
    this.getResponse()
    this.getUsers()
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Detail.scss";
</style>
<style scoped>
  .vue2leaflet-map .leaflet-shadow-pane {
    display: none !important;
  }

  .vs-input--label {
    padding: 0;
  }
</style>
